import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { localize } from "../lib/helpers"
import Typewriter from "typewriter-effect"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoNoControls from "../components/videoNoControls"
import BoldParagraph from "../components/boldParagraph"
import Cta from "../components/cta"

const ContactPage = ({ data, pageContext, location }) => {
  const
    { t } = useTranslation('common'),
    { _rawTitle, hello, intro, _rawVideo, _rawClients, _rawCollaborators } = localize(data.sanityContact, [pageContext.language]);

  return (
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location} />

      <div id="contact">
        <section className="contact--intro">
          <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="half-block">
            <h1 className="M60-regular">
              <Typewriter
                options={{
                  strings: hello.map(hel => hel),
                  autoStart: true,
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .changeDelay(120)
                    .start()
                }}
              />
            </h1>
            <BoldParagraph content={intro} containerClassName="half-cont" elemClassName="TT28-elight"/>
          </div>
          <VideoNoControls video={_rawVideo.vimeoVideo.oEmbedData.html}/>
        </section>

        <section className="grid-16 forms-cta">
          <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="cta-container clients">
            <h6 className="M16-regular">{t("clients")}</h6>
            <Cta cta={_rawClients}/>
          </div>
          <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="cta-container collaborators">
            <h6 className="M16-regular">{t("collaborators")}</h6>
            <Cta cta={_rawCollaborators}/>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactPage($language: String) {
    sanityContact {
      _rawTitle
      hello
      intro {
        translate(language: $language)
        isBold
      }
      _rawVideo
      _rawClients
      _rawCollaborators
    }
  }
`

export default ContactPage
